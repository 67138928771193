import React, { useState, useEffect } from 'react';
import battlersdata from '../constants/battlers';

import { Link } from 'react-router-dom';
import { useCustomContext } from '../state/context';
function App() {
const { cart, getTotalQuantity, getTotalPrice } = useCustomContext();
  const [battlers, setBattlers] = useState(battlersdata);
  return (
    <main className="blue-bg">
      <h2>Cart</h2>
      <div className="cart">
        {
          getTotalQuantity() > 0 ? 
          cart.map((item, index) => {
            return(
              <div key={item.id}>
                <h4>{item.name} * {item.quantity}</h4>
                </div>
            )
          })
          :
          <p className="p">No items in cart.</p>
        }
        <p class="p">Total: $FREE!</p>
        <p className="p">During the testing period we are not allowed to take money.</p>
      </div>
      
    </main>
  );
}

export default App;
