import React, { useState, useEffect } from 'react';
import battlersdata from '../constants/battlers';
import axios from 'axios';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";

import { Link } from 'react-router-dom';
import { useCustomContext } from '../state/context';

//const api = 'http://localhost:3000/';
const api = 'https://playground-battlers.vercel.app/';
function App() {

  const [user, setUser] = useState([]);
  const [battlers, setBattlers] = useState([]);
  const [battler, setBattler] = useState("");
const [loading, setLoading] = useState(false)
  const auth = getAuth();
  const [name, setName] = useState("");
  const [lead, setLead] = useState("");
  const [img, setImg] = useState("");
  const [bio, setBio] = useState("");
  const [hp, setHP] = useState(20);
  const [mp, setMP] = useState(5);
  const [str, setSTR] = useState(1);
  const [atk, setATK] = useState(1);
  const [def, setDEF] = useState(1);
  const [spd, setSPD] = useState(1);
  const [char, setCHAR] = useState(1);
  const [magic, setMagic] = useState(1);
  const [level, setLevel] = useState(1);
  const [exp, setEXP] = useState(0);
  const [wins, setWins] = useState(0);
  const [losses, setLosses] = useState(0)
  const [elo, setElo] = useState(1500)
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    axios.get(api + "getAll")
    .then(results => {
      
      setBattlers(results.data)
    })
    .catch(err => {
      console.log(err)
    })
  }, [])

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  function addCharacter(e) {
    e.preventDefault();

    const message = {
      name: name,
      img: img,
      lead: lead,
      bio: bio,
      visibile: isChecked,
      hp: hp,
      mp: mp,
      str: str,
      atk: atk,
      def: def,
      char: char,
      spd: spd,
      magic: magic,
      level: level,
      exp: exp,
      wins: wins,
      losses: losses,
      elo: elo
    }
    if (battler) {
      axios.post(api + "updateBattler",{
        message,
        id: battlers[battler].id
      }).then(results => {
        
      })
      .catch(err => {
        console.log(err);
      })
    } else {
    axios.post(api + "addBattler",{
      message
    }).then(results => {
      alert("Posted")
      setName("")
      setImg("")
      setBio("")
      setLead("")
      setIsChecked(false)
      setHP(20)
      setMP(5)
      setSTR(1)
      setATK(1);
      setDEF(1);
      setSPD(1);
      setCHAR(1);
      setMagic(1);
      setLevel(1);
      setEXP(0);
      setWins(0);
      setLosses(0);
      setElo(1500);
    })
    .catch(err => {
      console.log(err);
    })
  }
  }

  useEffect(() => {
    onAuthStateChanged(auth, (userCredential) => {
      // check if user is already logged in
      if (userCredential) {
        setUser(userCredential);
      } else {
        setUser(null);
      }
      setLoading(false)
    });
  }, []);

  const handleDropdownChange = (event) => {
    // Update the battler state with the selected value
    const index = event.target.value;
    setBattler(index);
    if (index) {
      // add values
      setName(battlers[index].name)
      setImg(battlers[index].img)
      setBio(battlers[index].bio)
      setLead(battlers[index].lead)
      setIsChecked(battlers[index].isChecked)
      setHP(battlers[index].hp)
      setMP(battlers[index].mp)
      setSTR(battlers[index].str)
      setATK(battlers[index].atk);
      setDEF(battlers[index].def);
      setSPD(battlers[index].spd);
      setCHAR(battlers[index].char);
      setMagic(battlers[index].magic);
      setLevel(battlers[index].level);
      setEXP(battlers[index].exp);
      setWins(battlers[index].wins);
      setLosses(battlers[index].losses);
      setElo(battlers[index].elo);
    }
    else {
      //clear values

      setName("")
      setImg("")
      setBio("")
      setLead("")
      setIsChecked(false)
      setHP(20)
      setMP(5)
      setSTR(1)
      setATK(1);
      setDEF(1);
      setSPD(1);
      setCHAR(1);
      setMagic(1);
      setLevel(1);
      setEXP(0);
      setWins(0);
      setLosses(0);
      setElo(1500);
    }
  };

  if (loading) return(<p>Loading</p>)
  return (
    <main className="blue-bg">
      
      {
        user ? 
        <>
        <select style={{marginBottom: "1rem", color: "#111"}} id="battlerSelect" value={battler} onChange={handleDropdownChange}>
        <option value="">Add Character</option>
       {
        battlers && 
        battlers.map((item, index) => {
          return (
            <option key={item.id} value={index}>{item.name}</option>
          )
        })
       }
      </select> 
           <button onClick={(e) => addCharacter(e)}>{battler ? "Update" : "Add"} character</button>
      
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
          <input type="text" value={img} onChange={(e) => setImg(e.target.value)} placeholder="Img #" />
          <input type="text" value={lead} onChange={(e) => setLead(e.target.value)} placeholder="Lead" />
          <textarea value={bio} onChange={(e) => setBio(e.target.value)} placeholder="Bio" />
          <input
        type="checkbox"
        id="myCheckbox"
        checked={isChecked}
        onChange={() => setIsChecked(old => !old)}
      /> <label htmlFor="myCheckbox">Display</label>
      <div className="stats-container">
        <div>
          <label>HP</label>
        <input type="number" value={hp} onChange={(e) => setHP(e.target.value)} placeholder="HP" />
        </div>
        <div>
          <label>MP</label>
      <input type="number" value={mp} onChange={(e) => setMP(e.target.value)} placeholder="MP" />
      </div>
      <div>
          <label>STR</label>
          <input type="number" value={str} onChange={(e) => setSTR(e.target.value)} placeholder="STR" />
          </div>
          <div>
          <label>ATK</label>
          <input type="number" value={atk} onChange={(e) => setATK(e.target.value)} placeholder="ATK" />
          </div>
          <div>
          <label>DEF</label>
          <input type="number" value={def} onChange={(e) => setDEF(e.target.value)} placeholder="DEF" />
          </div>
          <div>
          <label>SPD</label>
          <input type="number" value={spd} onChange={(e) => setSPD(e.target.value)} placeholder="SPD" />  
          </div>
          <div>
          <label>CHAR</label>
          <input type="number" value={char} onChange={(e) => setCHAR(e.target.value)} placeholder="Charisma" />
          </div>
          <div>
          <label>MGC</label>
          <input type="number" value={magic} onChange={(e) => setMagic(e.target.value)} placeholder="Magic" />
          </div>
          <div>
          <label>LVL</label>
          <input type="number" value={level} onChange={(e) => setLevel(e.target.value)} placeholder="Level" />
          </div>
          <div>
          <label>EXP</label>
          <input type="number" value={exp} onChange={(e) => setEXP(e.target.value)} placeholder="EXP" />
          </div>
          <div>
          <label>Wins</label>
          <input type="number" value={wins} onChange={(e) => setWins(e.target.value)} placeholder="Wins" />
          </div>
          <div>
          <label>Losses</label>
          <input type="number" value={losses} onChange={(e) => setLosses(e.target.value)} placeholder="Losses" />
          </div>
          <div>
          <label>Elo</label>
          <input type="number" value={elo} onChange={(e) => setElo(e.target.value)} placeholder="Elo" />
          </div>
            </div>
        </>
        :
        <button onClick={() => signInWithGoogle()}>Sign in with Google</button>
   
      }
      
    </main>
  );
}

export default App;
