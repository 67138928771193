import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomContext } from '../state/context';
import battlersdata from '../constants/battlers';
import axios from 'axios';

let api = 'http://localhost:3000/';
api = 'https://playground-battlers.vercel.app/';
function App() {
  const params = useParams();
 
  const [battler, setBattler] = useState(battlersdata.find(obj => obj.id == params.id));
  const { addToCart} = useCustomContext();
  const [loading, setLoading] = useState(true);
  const [sortedMatches, setSortedMatches] = useState([]);
  const [matches, setMatches] = useState([]);
  const [resolvedNames, setResolvedNames] = useState([]);

  useEffect(() => {
    if (matches) {
    // Calculate and sort the matches when the component mounts or when matches change
    const calculatedMatches = calculateMatches(matches);
    const sorted = sortMatches(calculatedMatches);
    setSortedMatches(sorted);
    }
  }, [matches]);

  const calculateMatches = (matches) => {
    console.log(matches)
    const calculatedMatches = {};

    matches.forEach(match => {
      const { winner, loser } = match;
      
      // Initialize counts if not already present
      calculatedMatches[winner] = calculatedMatches[winner] || { wins: 0, losses: 0, total: 0 };
      calculatedMatches[loser] = calculatedMatches[loser] || { wins: 0, losses: 0, total: 0 };

      // Update counts
      calculatedMatches[winner].wins += 1;
      calculatedMatches[loser].losses += 1;
      calculatedMatches[winner].total += 1;
      calculatedMatches[loser].total += 1;
    });

    return calculatedMatches;
  };

  const sortMatches = (matches) => {
    const sortedArray = Object.entries(matches).sort(([, a], [, b]) => b.total - a.total);
    return sortedArray.map(([id, stats]) => ({ id, ...stats }));
  };
  useEffect(() => {
    axios.post(api + "getOne", {
      id: params.id
    })
    .then(result => {
      setBattler(result.data)
      setLoading(false)
    })
  },[])

  useEffect(() => {
    axios.get(api + "getGames")
    .then(result => {
      setMatches(result.data)
    })
    .catch(err => {
      console.log(err)
    })
  },[])

  function getOne(id) {
    return axios.post(api + "getOne", { id: id })
      .then(result => {
        return result.data.name;
      });
  }
  const renderMatchList = () => {
    return resolvedNames.map((name, index) => (
      <li key={index} className="p">
        {index === 0 && "RIVAL! "}
        {name} | Wins: {sortedMatches[index].wins} | Losses: {sortedMatches[index].losses} | Total: {sortedMatches[index].total}
      </li>
    ));
  };
  useEffect(() => {
    const fetchNames = async () => {
      try {
        const namesPromises = sortedMatches.map(({ id }) => getOne(id));
        const resolvedNames = await Promise.all(namesPromises);
        setResolvedNames(resolvedNames);
      } catch (error) {
        console.error('Error fetching names:', error);
      }
    };
  
    fetchNames();
  }, [sortedMatches]);

  if (loading) { return <p>Loading</p>}
  return (
    <main className="blue-bg">
      
     <h2>{battler.name}</h2>
     <img src={require('../img/' + battler.img)} className="bio-img" alt={battler.name} />
     <button onClick={() => addToCart(battler)}>Add to cart</button>
     <p className="lead">{battler.lead}</p>
     <p style={{fontSize: "1.4rem"}}>{battler.bio}</p>
      <h2>Match History</h2>
     <ul>
        {renderMatchList()}
      </ul>
    </main>
  );
}

export default App;
