import React, { useState } from 'react';
import { useCustomContext } from "../state/context"
import { Link } from 'react-router-dom';
import { signOut, getAuth } from 'firebase/auth';
export default function Navbar() {

  const auth = getAuth();
  const { getTotalQuantity } = useCustomContext();

  return (
    <>
    <header>
      <h1><Link to="/">West Hopken</Link></h1>

     <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/cart">Cart({getTotalQuantity() > 0 ? getTotalQuantity() : 0})</Link></li>
      </ul>
    </header>
    </>
  )
}