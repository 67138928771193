import React, { useState, useEffect } from 'react';
import battlersdata from '../constants/battlers';

import { Link } from 'react-router-dom';
import { useCustomContext } from '../state/context';
function App() {

  const [battlers, setBattlers] = useState(battlersdata);
  return (
    <main className="blue-bg">
      <h2>West Hopken: A Wrestling Haven Facing an Unseen Evil</h2>

<h3>Introduction</h3>
<p className="p">
Nestled within the picturesque landscapes of British Columbia, Canada, lies the charming and fictional town of West Hopken. With a humble population of 54, this town boasts an unexpected claim to fame – it is a wrestling haven. West Hopken stands as a unique community where diversity thrives, welcoming individuals of all genders, sexualities, ethnicities, and religions. For the most part, life in West Hopken has been harmonious, with its wrestling culture acting as a unifying force. However, recent times have witnessed an ominous shadow descending upon the town, as an evil force has begun to settle, threatening the very fabric of this tight-knit community.
</p><h3>
The Genesis of West Hopken
</h3><p className="p">
Founded in the early 20th century, West Hopken was born out of the dreams of adventurous settlers who sought a new beginning in the vast and untamed landscapes of British Columbia. Over the years, the town evolved, adapting to the changing times while retaining its unique character. With a population that has remained relatively small, West Hopken has grown into a haven for wrestling enthusiasts, attracting both locals and visitors alike.
</p><h3>
Diversity as a Pillar
</h3>
<p className="p">
One of the defining features of West Hopken is its commitment to diversity. In a world often divided by differences, the people of West Hopken have embraced a spirit of inclusivity. The town's population reflects a rich tapestry of humanity, where individuals of different backgrounds coexist harmoniously. Regardless of one's gender, sexuality, ethnicity, or religious beliefs, everyone in West Hopken is not only accepted but celebrated for their uniqueness.
</p>
<h3>
The Wrestling Culture</h3>
<p className="p">
At the heart of West Hopken's identity is its thriving wrestling culture. The town's main industry revolves around the art of wrestling, with a dedicated community of wrestlers, trainers, and fans. The local wrestling arena, aptly named "Hopken Haven," serves as a gathering place for the townsfolk, where they come together to witness breathtaking displays of athleticism and skill. Wrestling has become more than just a sport in West Hopken; it's a way of life that binds the community together.
</p>
<h3>The Golden Years</h3>
<p className="p">
For much of its existence, West Hopken enjoyed a tranquil existence. The wrestling events brought joy to the residents, acting as a source of entertainment and unity. The town became known for its hospitality, with visitors often remarking on the warmth of its people and the unique charm that permeated the air. The inclusive atmosphere fostered a sense of belonging that made West Hopken not just a location but a true home for its residents.
</p>
<h3>The Unseen Evil</h3>
<p className="p">
However, recent times have cast a foreboding shadow over West Hopken. An unseen evil force has begun to settle within the town, disrupting the once harmonious way of life. Whispers of discontent and unease fill the air, as the residents grapple with an unfamiliar and malevolent energy. The wrestling matches, once a source of joy, have become tainted by an aura of darkness, as if the very essence of the town's identity is under siege.
</p>
<h3>The Wrestling Ring and Unexplained Phenomena</h3>
<p className="p">
The epicenter of West Hopken's wrestling culture, the Hopken Haven, has become the focal point of the town's woes. Wrestlers have reported strange occurrences within the ring – unexplained shadows, chilling whispers, and a pervasive sense of dread. The once lively arena now echoes with an eerie silence when the lights go out, and the unsettling feeling of being watched lingers long after the last match has ended.
</p>
<h3>Community Fractures</h3>
<p className="p">
As the unseen evil tightens its grip on West Hopken, the community begins to fracture. What was once a bastion of unity is now marked by distrust and suspicion. The vibrant tapestry of diversity that defined the town is unraveling as fear takes hold. The wrestling events, once attended by the entire community, now see dwindling crowds as residents retreat into the safety of their homes.
</p>
<h3>The Quest for Answers</h3>
<p className="p">
In the face of this unprecedented threat, a group of brave individuals in West Hopken has come together to unravel the mystery behind the unseen evil. Wrestlers, townsfolk, and even outsiders united by their love for West Hopken embark on a quest for answers. They delve into the town's history, seeking clues in old archives and consulting the few remaining elders who might hold the key to West Hopken's salvation.
</p>
<h3>
Conclusion</h3>
<p className="p">
West Hopken, once a haven of diversity and wrestling, now stands at a crossroads between its illustrious past and an uncertain future. The residents, united by their shared love for the town, must confront the unseen evil that threatens to tear apart the fabric of their community. As they navigate the shadows that loom over Hopken Haven, the people of West Hopken will discover the true strength of their unity and the power that diversity holds in overcoming even the darkest forces. The tale of West Hopken serves as a testament to the resilience of communities in the face of adversity, reminding us that sometimes, the greatest strength lies in the bonds we forge with one another.
</p>
      
    </main>
  );
}

export default App;
