import React, { createContext, useContext, useState, useEffect } from 'react';

import axios from 'axios';
const Context = createContext();

//const api = 'http://localhost:3000/';
const api = 'https://playground-battlers.vercel.app/';
export const ContextProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [things, setThings] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [total, setTotal] = useState(0);

  useEffect(() => {
    axios.get(api + "getAll")
    .then(results => {
      
      setThings(results.data)
    })
    .catch(err => {
      console.log(err)
    })
  }, [])
  useEffect(() => {
    // Load the cart data from localStorage when the component mounts
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      // If there's data in localStorage, parse it and set it as the initial cart state
      setCart(JSON.parse(savedCart));
    }
  }, []);
  function addToCart(item) {
    console.log(item)
    // Make a copy of the current cart state
    const updatedCart = [...cart];
  
    // Check if the item is already in the cart
    const existingItem = updatedCart.find((cartItem) => cartItem.id === item.id);
  
    if (existingItem) {
      // If the item is in the cart, increase its quantity
      existingItem.quantity += 1;
    } else {
      // If the item is not in the cart, add it with a quantity of 1
      updatedCart.push({ ...item, quantity: 1 });
    }
    console.log(updatedCart)
    // Update the cart state with the updatedCart
    setCart(updatedCart);
  }
  function updateQuantity(item, direction) {
    // Make a copy of the current cart state
    const updatedCart = [...cart];
  
    // Find the item in the cart
    const selectedItem = updatedCart.find((cartItem) => cartItem.id === item.id);
  
    if (selectedItem) {
      if (direction === 'increase') {
        // Increase the quantity of the selected item
        selectedItem.quantity += 1;
      } else if (direction === 'decrease' && selectedItem.quantity > 1) {
        // Decrease the quantity of the selected item (if it's more than 1)
        selectedItem.quantity -= 1;
      }
    }
  
    // Update the cart state with the updatedCart
    setCart(updatedCart);
  }

  function removeFromCart(item) {
    // Make a copy of the current cart state and filter out the item to remove
    const updatedCart = cart.filter((cartItem) => cartItem.id !== item.id);
    
    // Update the cart state with the updatedCart
    setCart(updatedCart);
  }
  function getTotalQuantity() {
    // Initialize a variable to store the total quantity
    let total = 0;

    // Iterate through the items in the cart and add their quantities
    cart.forEach((item) => {
      total += item.quantity;
    });

    return total;
  }
  function getTotalPrice() {
    // Initialize a variable to store the total price
    let totalPrice = 0;

    // Iterate through the items in the cart and add their prices multiplied by quantity
    cart.forEach((item) => {
      totalPrice += item.price * item.quantity;
    });

    return totalPrice.toFixed(2); // Format the total price to two decimal places
  }

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
    setQuantity(getTotalQuantity());
    setTotal(getTotalPrice())
  },[cart, getTotalQuantity, getTotalPrice])
  return (
    <Context.Provider value={{ total, quantity, things, cart,getTotalQuantity, addToCart, removeFromCart, updateQuantity }}>
      {children}
    </Context.Provider>
  );
};

export const useCustomContext = () => useContext(Context);