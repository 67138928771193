import React, { useState, useEffect } from 'react';
import battlersdata from '../constants/battlers';

import { Link } from 'react-router-dom';
import { useCustomContext } from '../state/context';
import axios from 'axios';

//const api = 'http://localhost:3000/';
const api = 'https://playground-battlers.vercel.app/';
function App() {

  const [battlers, setBattlers] = useState();
  const [reload, setReload] = useState(false);
  const [sortProp, setSortProp] = useState("elo")
  useEffect(() => {
    axios.get(api + "getAll")
    .then(results => {
      
      let data = sortByPropertyDescending(results.data, sortProp)
      setBattlers(data)
    })
    .catch(err => {
      console.log(err)
    })
  }, [reload])

  useEffect(() => {
      const fetchData = () => {
        playGame();
      }

    // Fetch data every 1000 milliseconds
    const intervalId = setInterval(fetchData, 10);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function playGame() {
    axios.get(api + "playGame")
      .then(result => {
        setReload(old => !old)
        
      })
      .catch(err => {
        console.log(err);
      })
  }
  function clearAll() {
    axios.get(api + "clearAll")
      .then(result => {
        
      })
      .catch(err => {
        console.log(err);
      })
      setReload(old => !old)
  }
  function sortByPropertyDescending(array, property) {
    return array.slice().sort((a, b) => {
      if (a[property] > b[property]) {
        return -1;
      }
      if (a[property] < b[property]) {
        return 1;
      }
      return 0;
    });
  }
  return (
    <main>
      {/* <button onClick={() => playGame()}>Play game</button>
      <button onClick={() => clearAll()}>Clear all</button> */}
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>HP</th>
          <th>MP</th>
          <th>STR</th>
          <th>ATK</th>
          <th>DEF</th>
          <th>SPD</th>
          <th>CHAR</th>
          <th>MGC</th>
          <th>EXP</th>
          <th>LVL</th>
          <th onClick={() => setSortProp("wins")}>Wins</th>
          <th onClick={() => setSortProp("losses")}>Losses</th>
          <th onClick={() => setSortProp("elo")}>Elo</th>
        </tr>
      </thead>
      <tbody>
      {
       battlers ? battlers.map((item, index) => {
          return(
            <tr key={item.id}>
              <td>
            <img className="table-img" src={require('../img/' + item.img)} Alt="Image" />
            </td>
            <td>
            <Link to={"/battler/" + item.id}>{item.name}</Link></td>
            <td>{item.hp}</td>
            <td>{item.mp}</td>
            <td>{item.str}</td>
            <td>{item.atk}</td>
            <td>{item.def}</td>
            <td>{item.spd}</td>
            <td>{item.char}</td>
            <td>{item.magic}</td>
            <td>{item.exp}</td>
            <td>{item.level}</td>
            <td>{item.wins}</td>
            <td>{item.losses}</td>
            <td>{item.elo}</td>
            </tr>
          )
        })
        :
        ""
      }
      </tbody>
      </table>
    <div className="welcome blue-bg">
      <h2>Welcome to West Hopken!</h2>
      <p className="p">We hope you enjoy your stay. Read about our wrestlers. Rent a clone (coming soon!). Have fun!</p>
      <p className="p">Two battlers are chosen at random. They fight with a mixture of skill and dice rolling until one runs out of health. Through the battle they can use magic and charisma to heal.</p>
    </div>
    </main>
  );
}

export default App;
