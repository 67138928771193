const battlers = [
  {
    id: 0,
    name: "Dude Fieldstein",
    lead: "Dude Fieldstein, the captivating 24-year-old wrestling dynamo hailing from Pittsburgh, Pennsylvania, is a rising star in the world of professional wrestling. ",
    bio: "Dude's journey, rooted in a high school wrestling passion, has evolved into a captivating odyssey of athleticism and charisma that transcends the squared circle. Born on November 15, 1999, Dude's foray into the professional wrestling scene commenced at the tender age of 21, making an indelible mark with his signature move, the \"Fieldstein Fury.\"\n\nChoosing to pursue his childhood dream, Dude enrolled in a prestigious wrestling school, dedicating himself to mastering the art of sports entertainment. His early years were marked by a relentless commitment to amateur wrestling, where he distinguished himself with natural athleticism and an unmatched work ethic. This foundation laid the groundwork for his seamless transition into the professional realm.\n\nAt 21, Dude Fieldstein made his debut on the grand stage, instantly captivating audiences with a unique fusion of power, agility, and an unyielding spirit. His authenticity both inside and outside the ring garnered respect, and the \"Fieldstein Fury\" became emblematic of his dominance. Beyond the spotlight, Dude remains humble, acknowledging the unwavering support of family, friends, and the ever-growing legion of fans who have become the heartbeat of his career.\n\nNow, at 24, Dude stands at the precipice of greatness, a testament to resilience, passion, and unwavering determination. Each match, each dramatic showdown, contributes to the evolving narrative of Dude Fieldstein, a narrative that resonates far beyond the confines of the wrestling world. As he continues to redefine the parameters of success in the squared circle, Dude remains an inspiration to a new generation of wrestling enthusiasts, a beacon of what it means to chase one's dreams with unbridled fervor.",
    img: "dude_front.png"
  },
  {
    id: 1,
    name: "Esme Pound",
    lead: "Esme Pound, a formidable force in the wrestling world at the age of 45, is more than a powerhouse in the ring—she's a devoted mother, wife, and the reigning Big Boss.",
    bio: "Hailing from a background as diverse as her wrestling repertoire, Esme's journey is as captivating outside the squared circle as it is within.\n\nAt the heart of her world are her loved ones. Esme's son, Paisley, is not just her biggest fan but a constant source of inspiration. With a supportive husband, Sonny, by her side, the Pound family thrives on unity and resilience. Beyond blood ties, Esme's compassion extends to her adopted daughter, Julia, forging bonds that transcend the conventional.\n\nIn the ring, Esme Pound's presence is commanding. Her signature moves resonate power, experience, and a wealth of tactical prowess earned over years of dedicated wrestling. As the Big Boss, she commands respect, leading by example both as an athlete and as a mentor to aspiring talents. Esme's legacy is etched not only in championship titles but in the lives she's touched, the challenges she's conquered, and the trail she continues to blaze, proving that age is no barrier to success in a world where strength, skill, and heart define true greatness.",
    img: "esme_front.png"
  },
  {
    id: 2,
    name: "Firehose Johnston",
    lead: "At the tender age of 18, Firehose Johnson bursts onto the wrestling scene, a vibrant force with brilliant red hair that mirrors the fire within.",
    bio: " Despite her youth, Firehose grapples with nerves, a constant companion offstage. Yet, it is precisely this vulnerability that makes her journey so compelling.\n\nHailing from a small town, Firehose's passion for wrestling ignited early. Her anxiety, a testament to the enormity of her dreams, never hinders her determination. In the ring, she transforms, unleashing a torrent of energy and skill that belies her nervous exterior. The brilliance of her red hair becomes a symbol of her fiery spirit, captivating audiences with each move.\n\nOff the mat, Firehose navigates the challenges of adolescence. Her nerves, a reminder of her humanity, endear her to fans who see a reflection of their own insecurities in her. A supportive family stands behind her, encouraging Firehose to overcome not just opponents in the ring but the internal battles that accompany youth.\n\nDespite her nervous disposition, Firehose Johnson embodies the future of wrestling—an amalgamation of raw talent, unbridled passion, and the courage to confront fears head-on. As she steps into the spotlight, the brilliant red of her hair becomes a beacon of hope for the underdog, proving that even the most nervous hearts can blaze a trail of triumph in the dazzling world of professional wrestling.",
    img: "firehose_front.png"
  },
  {
    id: 3,
    name: "Julia Tanktop",
    lead: "At 28, Julia Tanktop is a unique presence in the wrestling world, an enigmatic figure with a past as unconventional as her name.",
    bio: "Abandoned by her parents at the tender age of 6, Julia found solace in the companionship of her feline confidant, Puddles. Living in a cave, she embraced solitude and forged an unbreakable bond with the echoes of silence.\n\nJulia's journey into wrestling is one of resilience and self-discovery. The hardships of her past fueled a tenacious spirit that translates seamlessly into her performances in the ring. Her wrestling persona, defined by strength and mystery, draws fans into the captivating enigma that is Julia Tanktop.\n\nIn the solitude of her cave dwelling, Julia discovered a connection with Puddles that transcends the limitations of human relationships. The cat, a silent witness to her triumphs and tribulations, became an inseparable part of Julia's unconventional life. Together, they navigate the shadows, finding strength in each other's company.\n\nLiving on the fringe of societal norms, Julia's cave becomes a metaphorical battleground where she confronts not only opponents in the ring but also the ghosts of her past. Her unorthodox lifestyle, coupled with an unwavering commitment to her craft, positions Julia Tanktop as a symbol of individuality and defiance in the world of professional wrestling—a testament to the strength that can emerge from the most unexpected corners of life.",
    img: "julia_front.png"
  },
  {
    id: 4,
    name: "Leslie Bibman",
    lead: "Meet the enigmatic figure of the wrestling world, a 28-year-old non-binary force that defies convention—always masked, perpetually rude, and unapologetically pretentious.",
    bio: "The persona of this wrestler, whose identity remains shrouded, challenges both opponents and fans to question the norms of politeness in the cutthroat arena of professional wrestling.\n\nIn a world where personalities often vie for popularity, this non-binary enigma thrives on pushing boundaries. The mask, a symbolic barrier, shields their identity while amplifying the aura of mystery that surrounds their every move. Each gesture, each word, is carefully calculated, creating an air of arrogance that captivates and repels in equal measure.\n\nBehind the mask, a complex narrative unfolds. The non-binary wrestler's journey is marked by defiance—a rebellion against societal expectations and an assertion of their right to be unapologetically different. The wrestling ring becomes a canvas for their expression, where rudeness and pretentiousness are not just traits but strategic tools to assert dominance.\n\nOffstage, this non-binary force maintains an air of secrecy. Their personal life is shielded, and interactions are marked by a deliberate distance. Fans are left to speculate about the person behind the mask, adding an extra layer of intrigue to their character.\n\nIn a realm where heroes and villains are defined by shades of gray, this non-binary wrestler challenges perceptions, forcing spectators to grapple with discomfort and question their own biases. While the mask may conceal their face, it reveals a story of resistance, a narrative that defies conformity in a sport where rebellion is often the path to glory.",
    img: "leslie_front.png"
  },
  {
    id: 5,
    name: "Paisley Pound",
    lead: "At 24, Paisley Johnson emerges as a formidable force in the wrestling arena, but his prowess is matched only by his unapologetic arrogance.",
    bio: "As the son of the revered Big Boss, Paisley carries the weight of expectations on his shoulders, and he revels in the spotlight, flaunting both his skill and his attitude with equal flair.\n\nWith a natural athleticism inherited from his wrestling lineage, Paisley's in-ring performances are marked by a calculated brilliance that demands attention. His moves are executed with precision, and opponents find themselves grappling not just with his physical prowess but also with the audacity that emanates from every gesture.\n\nArrogance, for Paisley, is not just a character trait but a strategy. He uses it to unsettle opponents, leaving them disoriented and vulnerable. The confidence he exudes, bordering on cockiness, has become a trademark that sets him apart in a world where humility often takes a backseat to bravado.\n\nBeing the son of the Big Boss brings both privileges and pressures. Paisley walks the fine line between leveraging his family legacy and establishing himself as a force to be reckoned with in his own right. The expectations are high, but he embraces them, driven by an unyielding desire to carve his own legacy.\n\nOffstage, Paisley's arrogance is not just a persona; it's a shield. The real challenges he faces as the scion of a wrestling icon are often masked by a façade of overconfidence. As he navigates the complexities of fame and familial expectations, Paisley Johnson stands at the crossroads of arrogance and accomplishment, a 24-year-old upstart determined to leave an indelible mark on the canvas of professional wrestling.",
    img: "paisley_front.png"
  },
  {
    id: 6,
    name: "Sonny Pound",
    lead: "At 42, Sonny Pound strides into the wrestling arena with the commanding presence of a Viking warrior. ",
    bio: "With a visage that mirrors the rugged strength of the ancient Norse, Sonny cuts an imposing figure—a bearded behemoth whose appearance alone strikes awe into the hearts of both opponents and fans.\n\nSonny's physicality is a testament to his commitment to the sport, reminiscent of the legendary warriors of old. His beard, a flowing cascade of strength, frames a face marked by the wear of countless battles in the squared circle. In every sinew and muscle, echoes the spirit of a Viking, a warrior undeterred by the passage of time.\n\nAs a wrestler, Sonny Pound embodies the indomitable spirit of a Viking raider. His moves are a storm, a tempest of power and precision that leaves opponents in its wake. The ring becomes his longship, and the clash of bodies his battle cry—a manifestation of the ancient warrior ethos reborn in the modern era.\n\nOffstage, Sonny's persona extends beyond the arena. In his everyday life, the Viking aesthetic seamlessly integrates into his identity, a symbol of strength and resilience. The bearded gaze, stoic and determined, reflects a wisdom earned through years of grappling with challenges, both within and outside the ring.\n\nSonny Pound, at 42, stands as a living embodiment of the timeless warrior archetype—a Viking in the realm of wrestling, a figure whose presence evokes the spirit of ancient legends. As he continues to carve his path through the modern gladiatorial arena, Sonny leaves an indelible mark—a Viking legacy etched in the annals of professional wrestling.",
    img: "sonny_front.png"
  },
  {
    id: 7,
    name: "Turner Overdrive",
    lead: "In the dark corners of the wrestling world looms the ominous figure of Turner Overdrive—an ancient force, both powerful and malevolent.",
    bio: "Time has etched a history of malevolence on this enigmatic persona, making Turner a foreboding presence, shrouded in mystery.\n\nHaving weathered the sands of time, Turner Overdrive is a relic of a bygone era, a living embodiment of the shadows that linger in the periphery. The mere mention of this name sends shivers down the spines of wrestlers and fans alike, for Turner's power lies not just in physical prowess but in a sinister cunning that has withstood generations.\n\nEvil courses through the veins of Turner Overdrive, and the wrestling ring becomes a theater of manipulation and deceit. Opponents, young and brimming with vitality, find themselves ensnared in a web of malevolence, facing an adversary whose experience transcends the boundaries of time.\n\nThe aura of Turner is one of darkness—a cloak that conceals secrets and schemes. The very air crackles with an otherworldly energy when this ancient figure steps into the spotlight. In the ring, every move is executed with a calculated cruelty, as if drawing upon the accumulated darkness of years gone by.\n\nThe legacy of Turner Overdrive is one stained with the tears of defeated foes and the echoes of malicious laughter. With a sinister grin etched on a face that has seen countless battles, Turner stands as a testament to the enduring nature of evil in a sport where heroes rise and fall. As the wrestling world grapples with the malevolent presence of Turner Overdrive, a question lingers—how does one defeat a force that seems to transcend time itself?",
    img: "turner_front.png"
  },
]

export default battlers;