import React, { useState, useEffect} from 'react';
import './App.css';
import './constants/firebase';

import Home from './pages/Home';
import Battler from './pages/Battler';
import Admin from './pages/Admin';
import About from './pages/About';
import Cart from './pages/Cart';
import Navbar from './components/Navbar'
import { ContextProvider } from './state/context'
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {

  return (
    <ContextProvider>
    <BrowserRouter>
    <div className="header-container">
      <Navbar />
      </div>
    <div className="container">
      
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/battler/:id" element={<Battler />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/about" element={<About />} />
          <Route path="/admin" element={<Admin />} />
          
        </Routes>
        </div>
      </BrowserRouter>
    </ContextProvider>
  );
}

export default App;
